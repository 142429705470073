$transition_fast: 250ms ease;
$transition: 500ms ease;
$transition_slow: 1000ms ease;

$primary_color: #273e47;
$secondary_color: #ffffff;
$complementary_color_dark: #273e47;
$complementary_color_light: #4098a0;

:export {
  transitionSlow: $transition_slow;
  transition: $transition;
  transitionFast: $transition_fast;

  primaryColor: $primary_color;
  secondaryColor: $secondary_color;
  complementaryColorDark: $complementary_color_dark;
  complementaryColorLight: $complementary_color_light;
}
