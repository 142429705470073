.nav_wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

#nav_container {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 10rem;
  width: 100%;
  padding: 1rem 4rem;

  @include size_M {
    padding: 1rem 2rem;
  }

  color: $secondary_color;
  z-index: 10;
  transition: $transition_fast;

  .nav_menu,
  .nav_logo,
  .nav_book {
    display: flex;
    align-items: center;
  }

  .nav_menu {
    &_burger {
      position: relative;
      width: 40px;
      height: 40px;
      cursor: pointer;
      transform: scale(1.5);

      &_cheeckbox {
        display: block;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        cursor: pointer;
        z-index: 2;
        -webkit-touch-callout: none;
        position: absolute;
        opacity: 0;
      }
      div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 22px;
        height: 12px;
      }
      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $secondary_color;
        border-radius: 1px;
        transition: $transition_fast;

        &:first-of-type {
          top: 0;
        }
        &:last-of-type {
          bottom: 0;
        }
      }
      &.active,
      &_cheeckbox:checked + div {
        span {
          &:first-of-type {
            background-color: $complementary_color_dark;
            transform: rotate(45deg);
            top: 5px;
          }
          &:last-of-type {
            background-color: $complementary_color_dark;
            transform: rotate(-45deg);
            bottom: 5px;
          }
        }
      }

      &.active:hover span:first-of-type,
      &.active:hover span:last-of-type,
      &:hover .nav_menu_burger_cheeckbox:checked + div span:first-of-type,
      &:hover .nav_menu_burger_cheeckbox:checked + div span:last-of-type {
        width: 22px;
      }

      &:hover {
        // no need hover effect on mobile.
        @media (min-width: 1024px) {
          span:first-of-type {
            width: 26px;
          }

          span:last-of-type {
            width: 12px;
          }
        }
      }
    }
  }

  .nav_logo {
    height: 8rem;
    margin: auto 0;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
      height: 100%;

      @include size_S {
        height: 80%;
      }

      transition: $transition_fast;
    }
  }

  .nav_book {
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 400;
    gap: 20px;

    @include size_M {
      display: none;
      #header_lang {
        display: none;
      }
    }

    &_lang {
      cursor: pointer;
      transition: $transition_fast;
    }

    div {
      display: flex;
      align-items: center;
      background-color: $complementary_color_dark;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
}

#nav_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  z-index: 8;
  transition: $transition_fast;
}

#nav_menu {
  overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 0;
  z-index: 9;
  transition: $transition;
  white-space: nowrap;

  .nav_menu_list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;

    @include size_S {
      grid-template-columns: 1fr;
    }

    background-color: $complementary_color_light;
    height: 100%;

    &_links {
      color: $complementary_color_dark;
      background-color: $secondary_color;
      text-align: start;
      padding: 10rem 4rem;
      padding-bottom: 2rem;

      @include size_M {
        padding: 10rem 2rem;
        padding-bottom: 2rem;
      }

      div {
        display: block;
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 2rem;
        cursor: pointer;
      }
    }

    &_image {
      display: flex;

      @include size_S {
        display: none;
      }

      img {
        height: 100vh;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

#menu_lang {
  display: none !important;
  @include size_M {
    display: block !important;
  }
}

.open_sidemenu {
  width: 100% !important;
}
