#footer {
  background: linear-gradient(180deg, $complementary_color_dark, $complementary_color_light 200%);
  color: $secondary_color;
  text-align: center;
  padding: 0rem 2rem;
  padding-top: 4rem;

  p {
    color: $secondary_color;
    opacity: 0.75;
  }

  img {
    height: 180px;
    margin-bottom: 2rem;
  }

  .footer {
    &_contact,
    &_links {
      margin-bottom: 4rem;
    }

    &_logo{
      cursor: pointer;
    }

    &_links {
      div {
        font-size: 1.2rem;
        font-weight: 400;
        display: block;
        margin-bottom: 1rem;
        transition: $transition;
        cursor: pointer;
      }
    }

    &_bottom {
      font-size: 0.8rem;

      p {
        font-size: 0.75rem;
        margin: 0;
        padding: 1rem 0;
      }
    }
  }
}
