#location {
  background-color: $complementary_color_dark;
  color: $secondary_color;
  padding-top: 2rem;
  padding-bottom: 6rem;

  h2 {
    text-align: center;
  }

  &_container {
    width: 100%;
    height: 600px;

    @include size_M {
      height: 400px;
    }
  }
}
